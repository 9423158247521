@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.contactus_heading {
    font-family: 'Inter';
    font-size: 16px
}

.contactus_button {
    background-color: rgb(143, 45, 45);
    color: white;
    
}

.contact-form {
    background-color: rgb(112, 151, 184);
    margin-top: 30px;
    margin-bottom: 30px;
}