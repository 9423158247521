@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.register_label {
    font-size: 14px !important;
    font-family: 'Inter';
    color: black !important;
}

.register-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
  }
  
  input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    /* width: 100%; */
  }
  
  button:hover {
    opacity: 0.9;
  }
  
  .next-btn {
    background-color: #007bff;
    color: white;
  }
  
  .prev-btn {
    background-color: #6c757d;
    color: white;
  }
  
  .submit-btn {
    background-color: #28a745;
    color: white;
  }
  
  button.prev-btn, button.next-btn, button.submit-btn {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  h3 {
    margin-top: 20px;
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .required {
    color: red;
  }

  
.back-button {
  position: absolute;
  left: 10px; 
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #007bff;
  font-family: 'Inter';
}