@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0; 
}

.ac_doorstep {
    font-family: 'Inter';
    font-size: 36px;
    font-weight: 800;
    color: #FFFFFF;;
    padding-top: 100px;
}

.store_icon_img {
    padding-top: 40px;
}

.homepage_acimg {
    max-width: 100%;  
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home_doorstep {
    background-color: #0F172A;
    margin-bottom: 50px;
}

.home_page_heading {
    font-family: 'Montserrat';
    font-size: 48px;
    color: #000000;
    font-weight: 700;
    margin-top: 75px;
}

.home_page_para {
    font-family: 'Inter';
    font-size: 10px;
    color: #ffffff;
    font-weight: 500;
}

.home_page_subparagraph {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 22px;
    color:#7A7A7A;
    /* text-align: center; */
    justify-content: center;
}

.bg_color {
    background-color: #0F172A;
}

.home_page_subpara {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
}

.line {
    width: 10%;        
    height: 6px;        
    background-color:  #1B2336;  
    margin-top: 10px;    
}

.header_line {
    width: 100%;
    height: 2px;
    background-color:  #D3D3D3;
}

.home_doorsteo_text {
    
    font-family: 'Inter';
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 400;
    align-items: center;

}

.home_service_bg_img {
    background-image: url('../asserts/homeService_doorstep.png');
    background-size: cover;       
    background-position: center;   
    background-repeat: no-repeat;   
    width: 100%;
    height: 640px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 50px 0;
}


/* Medium screens (≥768px and <992px) */
@media (max-width: 991px) {
    .home_service_bg_img {
        height: 500px;
        padding: 40px 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ac_doorstep {
        font-size: 30px;
    }

    .home_doorsteo_text {
        font-size: 14px;
    }

    .store_icon_img {
        width: 80%;
        max-width: 100px;
    }
}


@media (max-width: 767px) {
    .home_service_bg_img {
        height: 400px;
        padding: 30px 0;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ac_doorstep {
        font-size: 24px;
    }

    .home_doorsteo_text {
        font-size: 12px;
    }

    .store_icon_img {
        width: 60%;
        max-width: 80px;
    }
}


@media (max-width: 575px) {
    .home_service_bg_img {
        height: 300px;
        padding: 20px 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .ac_doorstep {
        font-size: 20px;
    }

    .home_doorsteo_text {
        font-size: 10px;
    }

    .store_icon_img {
        width: 50%;
        max-width: 60px;
    }
}


/* FAQ */

.home_page_faq {
    text-align: center;
    align-items: center;
}

.faq_faq {
    padding-top: 50px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 700;
    color: #0F172A;
}

.faq_text_heading {
    font-family: 'Inter';
    font-size: 26px;
    font-weight: 700;
    color: #000000;
}

.faq_text {
    font-family: 'Inter';
    font-size: 16;
    font-weight: 400;
    color:  #7A7A7A;
    padding-bottom: 30px;

}

.faq_questions {
    font-family: 'Inter';
    font-size: 18px;
    background-color:#0F172A ;
    font-weight: 600;
    color:#FFFFFF;
}

.faq_accordion {
    background-color: #6A789B ;
    font-family: 'Inter';
    font-size: 16px;
    color: #FFFFFF;
}

.faq_accordion_head {
    background-color:#0F172A ;

}
.faq_image {
    align-items: center;
    text-align: center;
    justify-content: center;

}



.accordion-button {
    /* background-color: #f8f9fa;  */
    /* color: #0f172a;  */
}


.accordion-button:not(.collapsed) {
    background-color: #0F172A;
    color: white; 
}



@media (max-width: 991px) {
    .container .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faq_image {
        width: 80%; 
        margin-bottom: 30px;
    }

    .faq_accordion {
        margin: 0 20px;
        font-size: 14px;
    }
}


@media (max-width: 575px) {
    .faq_image {
        width: 90%; 
        margin-bottom: 20px;
    }

    .faq_questions {
        font-size: 16px; 
    }

    .faq_accordion {
        font-size: 14px;
    }
}


/* conatctus */

.contactus_homepage {
    margin-top: 100px;
    padding-left: 200px;
    padding-right: 30px;
    margin-bottom: 50px;
    background-color: #E1E4E9;
}

.homepage_contactus {
    padding-top: 80px;
    font-family: 'Inter';
    font-size: 26px;
    font-weight: 700;
    color: #1B2336;
}

.contact_form_top {
    margin-top: 50px;
}
.homepage_contact_input {
    border-color:  #C8C8C8 !important;
    padding: 10px !important;
}

.homepage_contact_input::placeholder {
    font-family: 'Inter';
    font-size: 14px; 
}

.contactus_submit_button {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    background-color: #0F172A;
    color: #FFFFFF;
}


.homepage_contactus_secondcolumn {
    padding-top: 50px;
    background-color:#0F172A;
}


.homepage_contactus_secondcolumn p {
    margin: 0; 
    font-family: 'Inter';
    color: #FFFFFF;
}
.circle-container {
    width: 50px;  
    height: 50px;
    background-color: white;
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-right: 15px;  
}

.callus-image {
    width: 30px;  
    height: 40px;
    object-fit: cover; 
    border-radius: 50%; 
}

.social-icons {
    display: flex;
    align-items: center;  
    gap: 15px;  
}

.contact_social-icon {
    font-size: 30px; 
    color: #ffffff;  
    text-decoration: none;  
    padding-left: 10px;  
}

.contact_social-icon:hover {
    color: #192541;  
}

.mb-0 {
    margin-bottom: 0;  
}


@media (max-width: 576px) {
    .contactus_homepage {
        margin-top: 100px;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 50px;
        background-color: #E1E4E9;
    }
    .homepage_contact_input {
        border-color:  #C8C8C8 !important;
        margin-bottom: 10px;
    }
}



/* SUBSCRIBE */
.subscribe_container {
    background-color: #3B82F6;
    padding-left: 80px;
    padding-top: 50px;
    padding-bottom: 20px;
}
.subscribe_para {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    color: #FFFFFF;
    font-family: 'Inter';
    padding: 0;
    margin: 0;
}

.subscribe_input, .subscribe_button {
    height: 100%; 
    font-size: 16px; 
  }
  
  .subscribe_button {
    width: 50%; 
    background-color: #0F172A;
    color: #FFFFFF;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
  }

  .subscribe_container {
    margin-bottom: -45px;
    position: relative;
    z-index: 3;
  }
  

@media (min-width: 992px) {
    .subscribe_container {
        padding-left: 80px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .subscribe_para {
        font-size: 40px;
    }

    .subscribe_input, .subscribe_button {
        height: 100%;
        font-size: 16px;
    }

    .subscribe_button {
        width: 50%;
        background-color: #0F172A;
        color: #FFFFFF;
        font-family: 'Inter';
        font-size: 18px;
        font-weight: 600;
    }

    .subscribe_container {
        margin-bottom: -85px;
        position: relative;
        z-index: 3;
    }
}


@media (max-width: 991px) {
    .subscribe_container {
        padding-left: 40px;
        padding-right: 40px;
    }

    .subscribe_para {
        font-size: 30px;
    }

    .subscribe_input, .subscribe_button {
        height: 50px; 
    }

    .subscribe_button {
        width: 100%;
        font-size: 16px;
    }
}


@media (max-width: 767px) {
    .subscribe_container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .subscribe_para {
        font-size: 25px;
    }

    .subscribe_input, .subscribe_button {
        height: 45px;
        font-size: 14px;
    }

    .subscribe_button {
        width: 100%;
        font-size: 16px;
    }
}


@media (max-width: 575px) {
    .subscribe_container {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .subscribe_para {
        font-size: 20px;
    }

    .subscribe_input, .subscribe_button {
        height: 40px; 
        font-size: 12px;
    }

    .subscribe_button {
        width: 100%;
        font-size: 14px;
    }
}

/*  */

/* Contact us */
/* Flexbox styles for better responsiveness */
.contactus_homepage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.left-column,
.right-column {
    padding-left: 15px;
    padding-right: 15px;
}

/* Form row styling */
.form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-group {
    flex: 0 0 48%;
}

/* Styling for form controls */
.form-control {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
}

/* Submit button styling */
.submit-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.contactus_submit_button {
    padding: 12px 25px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.contactus_submit_button:hover {
    background-color: #0056b3;
}


.contact-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
}


.icon-container img {
    width: 30px;
    height: 30px;
    /* margin-right: 15px; */
}


.text-container p {
    margin: 0;
    line-height: 1.4;
}

.text-container p:first-of-type {
    font-weight: bold;
}


@media (max-width: 767px) {
    .left-column, .right-column {
        flex: 0 0 100%; 
    }
    .form-group {
        flex: 0 0 100%; 
    }
    .contact-detail {
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .left-column, .right-column {
        flex: 0 0 48%; 
    }
}

@media (min-width: 992px) {
    .left-column, .right-column {
        flex: 0 0 48%;
    }
}

/* FAQ */

.accordion-item {
    display: flex;
    flex-direction: column;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}

.accordion-collapse.collapse.show {
    display: block;
}

.accordion-collapse {
    overflow: hidden;
    height: auto;
}

.accordion-button {
    width: 100%;
    display: block;
    padding: 16px; 
}

.accordion-button.collapsed {
    padding-top: 16px; 
    padding-bottom: 16px;  
}

.accordion-button:not(.collapsed) {
    padding-top: 24px;  
    padding-bottom: 24px;  
}

.accordion-body {
    padding: 16px;  
    font-size: 16px;  
}

/* subscribe */

.subscribe_input {
    border-radius: 0; 
    width: 100%;
    height: 100%; 
}

.subscribe_button {
    border-radius: 2px; 
    width: 90%;
    height: 100%; 
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.row {
    display: flex;
    align-items: center; 
}

.col-12, .col-sm-8, .col-md-8, .col-lg-8 {
    padding-left: 0;
    padding-right: 0;
}

.col-sm-4, .col-md-4, .col-lg-4 {
    padding-left: 0;
    padding-right: 0;
}

.row.g-0 {
    margin-left: 0;
    margin-right: 0;
}

/* BANNER */
.add-banner {
    margin-top: 20px;
}

.card {
    background-color:  #0F172A;
    color: white; 
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    height: 250px;  
    box-sizing: border-box;  
}

.card-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-img {
    width: 120px;
    height: auto;
    object-fit: contain;
    margin-left: 15px;
}

.home_page_subpara {
    font-size: 18px;
    font-weight: bold;
}

.home_page_para {
    font-size: 14px;
}


@media (max-width: 767px) {
    .card {
        flex-direction: column;  
        height: auto; 
        text-align: center;  
    }

    .card-img {
        max-width: 100%;
        margin-top: 15px;
    }

    .card-text {
        margin-bottom: 15px;
    }
}
