
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.home_page_text {
    font-size: 22px;
    font-family: 'Inter';
}


.home_page_text {
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    position: relative; 
}

.home_page_text::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px; 
    height: 100%; 
    background-color: #0F172A ;
    transform: scaleY(0); 
    transform-origin: top; 
    transition: transform 0.3s ease; 
}

.home_page_text:hover::before {
    transform: scaleY(1); 
}




.dropdown {
    position: relative;
    display: inline-block;
    padding-top: 14px;
 }
 
 
 .dropdown-btn {
    background-color: #6b5fd1;
    color: white;
    border: none;
    padding: 10px 10px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
 }
 
 
 .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    z-index: 1;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px 0;   
 }
 
 .dropdown-content a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    font-family: 'Inter';
 }
 
 
 .dropdown:hover .dropdown-content {
    display: block;
 }
 

 .dropdown-content .login-btn:hover, 
 .dropdown-content .signup-btn:hover {
    background-color: #4c4bb8;
    transform: scale(1.05);
    color: #ffffff;
 }

 .dropdown-btn i {
    margin-right: 8px; 
    font-size: 16px;
 }
 

 .dropdown-btn .fa-caret-down {
    margin-left: 8px; 
    font-size: 14px; 
 }
 
 

.profile-icon:hover {
    color: #6b5fd1; 
}

.profile-icon {
    padding: 5px;
}


.btn-container a {
    text-decoration: none; 
}
