 :root {
    --primary-pro-color: #1364F1;
    --primary-bg-color: #1364F1;

}

@import url('https://fonts.googleapis.com/css?family=Roboto');
 

body {
    font-family: 'Roboto' !important
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #ddd;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-bg-color);
    border-radius: 5px;
}


.page-loader-dynamic {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: lightblue;
    opacity: 50%;
    cursor: pointer;
    z-index: 9999999;
    text-align: center;
    left: 0;
}

.page-loader-dynamic i {


    font-size: 100px;
    padding: 25%;
}


#navbar{

    background: #fff;
}

 



/***Login****/


.req-error-message{
    color: #ff0000 !important;
    text-align: left;
    padding: 0px 0px;
    margin: 0;
}
/***Login****/




/***Notify****/

#toast-notification { 
    position: fixed; 
    top: 0; 
    right: 1%; 
        width: 350px;
    max-width: 100%;
    z-index: 9999999;
    border-radius: 10px;
    display: none;
} 
#para { 
    border: 1px solid black; 
    width: 300px; 
    height: 100px; 
    overflow: scroll; 
} 
.toast-notification-color { 
    color: #000; 
    background-color: #fff; 
} 
.toast-alert-danger{
     color: #ff0000; 
    background-color: #fff; 

    border : 2px solid #ff0000;
}
.toast-alert-success{
     color: #5cb85c; 
    background-color: #fff; 

    border : 2px solid #5cb85c;
}
.fade-out{

     opacity: 0;
  transition: all 250ms linear 2s;
} 

/***Login****/
